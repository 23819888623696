body {
  font-family: "Merriweather", serif;
  font-weight: 200;
  font-size: 1.3rem;
  line-height: 3.2rem;
  background: #FAF6F0;
  color: #000;
}

#submissionBy{
  background-color: #fff;
  border-radius: 5px;
  font-size: 1rem;
  text-align: end;
}

#submissionBy a {
  color: #FFB534;
  font-weight:600;
}

.profile-photo-block{
  margin: 10px 5px 10px 5px;
}

.profile-photo{
  float:left;
  margin: 5px;
}

.profile-photo a{
  padding: 0px !important;
}

.profile-photo img{
  border-radius: 50% !important;
  max-height: 200px;
  max-width: 200px;
}

.content a, #extra a {
  background-color:#FFB534;
  color: #000;
  font-weight: 600;
  padding:.3rem;
  text-decoration: none;
}

.content a:hover, #extra a:hover {
  background: #FFB534;
  color:#000;
}

.content .dropdown a{
  background-color:#fff;
}

.content .dropdown a:hover{
  background-color:#F4EAE0;
}

.content img{
  float:left;
  border-radius:8px;
  border:#FFB534 solid 8px;
}

.navbar {
  font-family: "Roboto", sans-serif;
  font-weight:300;
  font-size:1rem;
}

.navbar-brand{
  font-family: "Merriweather", serif;
  font-weight:700;
}

#tagcloud text {
  cursor: pointer;
}

.link-callout{
  background-color:#FFB534;
  color: #fff;
  font-weight: 600;
  padding:.3rem;
  border-radius: 8px;
}

.link-callout:hover{
  background: #FFB534;
  color:#000;
}

.abst{
  margin-top: 1.3rem;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
  font-size: 1rem;
  text-align: justify;
}

.notes {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.notes .card {
  position:absolute;
}

@media (max-width:576px) {
  .notes .card:hover{
    opacity:0.2;
  }
}

@media (min-width:576px) {
  .notes .card:hover{
    background: #ffcf7a;
    color:#000;
  }
}

.card {
  font-family: "Open Sans";
  font-weight: 400;
 background-color: #F4EAE0;
  background-clip: border-box;
  border: 0;
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: #888;
  background-color: #fff;
  border-bottom: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: #888;
}

.accordion-button {
  background-color: #F4EAE0;
}


.accordion-button:not(.collapsed) {
  font-weight:700;
  background-color: #F4EAE0;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.badge a {
  text-decoration: none;
  color:#fff;
  font-weight:700;
  font-size: .8rem;
}

.ProseMirror blockquote {
  margin-left:25px;
}

.inlineNote{
  text-decoration-line: underline;
  text-decoration-color: #FFB534;
}

.inlineNote:hover{
  padding:2px;
  background: #FFB534;
  color:#000;
}

.markup{
  font-family: "IBM Plex Mono", monospace;
  font-weight:500;
  background-color: #F4EAE0;
  color: #888;
}

.copyBlock{
  text-align:end;
}

.copy, .copied{
  width: 30px;
  height: 30px;
}

.copy{
  fill:#888;
}

.copy:hover{
  fill:#C1F2B0;
}

.copied{
  fill:#C1F2B0;
}

.clearBlock button{
  text-align: center;
}

.editorIcon{
  width:30px;
  height: 30px;
}

.editorIcon.active{
  background-color: #FFB534;
}

.editorIcon:hover{
  background-color: #C1F2B0;
}

.editor .btn:disabled{
  border:0;
}

.editor button{
  padding:2px;
  margin:2px;
}

.editor .dropdown-menu{
  min-width: 98%;
  text-align:center;
}

.editor .btn-check:checked+.btn, .editor .btn.active, .editor .btn.show, .editor .btn:first-child:active, .editor :not(.btn-check)+.btn:active {
  border: 0px;
}

.editor .btn-outline, .clearBlock button{
  border:2px solid #F4EAE0;
  background-color: #888;
  color:#F4EAE0;
  font-weight:bold;
  padding: 5px;
  margin-top: 8px;
  margin-left: 10px;
  height: 80%;
}

.editor .btn-outline:hover, .clearBlock button:hover{
  border:2px solid #888;
  background-color: #F4EAE0;
  color:#888;
  font-weight:bold;
  padding: 5px;
  margin-top: 8px;
  margin-left: 10px;
  height: 80%;
}

.addLink{
  margin-top: -26px;
  margin-left: -35px;
}

.ProseMirror {
  max-height: 300px;
  overflow: auto;
}

.tiptap {
  background-color:#F4EAE0;
  border: 2px solid #888;
  border-radius: 7px;
  padding:5px;
}

.modal-body textarea{
  width:100%;
}

.tag-area a {
  background-color: transparent;
}

.tag-area {
  background: #fff;
  padding: 2px 2px 2px 10px;
  min-height: 3rem;
  border-radius: 5px;
  vertical-align: middle;
}

.rs-tag{
  float:inline-start
}

.rs-input{
  border: 1px solid #f7f7fa;
  background-color: #f7f7fa;
  float:inline-end;
  margin-top: 15px;
}

/*  Rsuite Styles for tags       */

.rs-tag {
  display: inline-block;
  background-color: #f7f7fa;
  background-color: var(--rs-tag-bg);
  color: #575757;
  color: var(--rs-text-primary);
  border-radius: 6px;
  /*line-height: 20px;*/
}
.rs-tag-closable {
  position: relative;
}
.rs-tag-icon-close {
  position: absolute;
  top: 0;
  right: 0;
}
.rs-tag-lg {
  padding: 5px 10px;
  font-size: 14px;
}
.rs-tag-lg .rs-tag-icon-close {
  padding: 9px 8px;
}
.rs-tag-closable.rs-tag-lg {
  padding-right: 27px;
}
.rs-tag-md {
  padding: 2px 8px;
  font-size: 12px;
}
.rs-tag-md .rs-tag-icon-close {
  padding: 6px 8px;
}
.rs-tag-closable.rs-tag-md {
  padding-right: 25px;
}
.rs-tag-sm {
  padding: 0 8px;
  font-size: 12px;
}
.rs-tag-sm .rs-tag-icon-close {
  padding: 4px 5px;
}
.rs-tag-closable.rs-tag-sm {
  padding-right: 25px;
}
.rs-tag + .rs-tag {
  margin-left: 10px;
}
.rs-tag-red {
  background-color: #f44336;
  background-color: var(--rs-red-500);
  color: #ffffff;
}
.rs-tag-orange {
  background-color: #fa8900;
  background-color: var(--rs-orange-500);
  color: #ffffff;
}
.rs-tag-yellow {
  background-color: #ffb300;
  background-color: var(--rs-yellow-500);
  color: #ffffff;
}
.rs-tag-green {
  background-color: #4caf50;
  background-color: var(--rs-green-500);
  color: #ffffff;
}
.rs-tag-cyan {
  background-color: #00bcd4;
  background-color: var(--rs-cyan-500);
  color: #ffffff;
}
.rs-tag-blue {
  background-color: #2196f3;
  background-color: var(--rs-blue-500);
  color: #ffffff;
}
.rs-tag-violet {
  background-color: #673ab7;
  background-color: var(--rs-violet-500);
  color: #ffffff;
}
.rs-tag-group {
  margin: -10px 0 0 -10px;
}
.rs-tag-group >  {
  margin-top: 10px;
  margin-left: 10px;
}

.rs-btn-close {
  border: none;
  outline: none !important;
  background: transparent;
  line-height: 2.2;
  cursor: pointer;
}
.rs-btn-close .rs-icon {
  vertical-align: bottom;
}
.rs-btn-close:hover,
.rs-btn-close:focus {
  color: #f44336;
  color: var(--rs-close-button-hover-color);
}
.rs-btn-close:hover svg path,
.rs-btn-close:focus svg path {
  stroke: #f44336;
  stroke: var(--rs-close-button-hover-color);
  stroke-width: 1;
}